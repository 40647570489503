<template>
  <div class="view-home">
    <div class="container">
      <div class="quiz-status my-4">
        <div class="card" v-show="countdown">
          <ul>
            <li class="time">
              {{ countdown }}
            </li>
            <li class="recording" v-if="quiz.proctoring">
              {{ $t('testing.recording') }}
            </li>
            <li class="interruption" v-if="quiz.proctoring">
              {{ $t('testing.interruption') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container questions-wrap">
      <h2>
        {{ $t('creativity-15-17.title') }}
      </h2>
      <h3>
        {{ $t('creativity-15-17.subTitle3') }}
      </h3>
      <div class="collaboration-desc">
        {{ $t('creativity-15-17.instruction3') }}
      </div>

      <div class="image-container">
        <div class="position-relative">
          <div class="border-dashed">
            <div class="container-image" ref="figureContainer_block1">
              <img
                  v-for="(figure, index) in figures1"
                  :key="index"
                  :src="figure.src"
                  :alt="figure.alt"
                  class="figure draggable"
                  @click="selectFigure(index, 'figures1', 'selectedFigure1')"
                  :style="getFigureStyle(figure)"
              />
            </div>
          </div>
          <div class="settings-panel" v-if="selectedFigure1 !== null">
            <h3>{{ $t('figureSettings') }}</h3>
            <label>
              {{ $t('width') }}:
              <input
                  type="range"
                  min="10"
                  max="320"
                  v-model.number="figures1[selectedFigure1].style.widthNumeric"
                  @input="updateFigureStyle('width', $event.target.value + 'px', 'figures1', 'selectedFigure1')"
              />
              {{ figures1[selectedFigure1].style.width }}
            </label>

            <label>
              {{ $t('height') }}:
              <input
                  type="range"
                  min="10"
                  max="320"
                  v-model.number="figures1[selectedFigure1].style.heightNumeric"
                  @input="updateFigureStyle('height', $event.target.value + 'px', 'figures1', 'selectedFigure1')"
              />
              {{ figures1[selectedFigure1].style.height }}
            </label>

            <label>
              {{ $t('angle') }}:
              <input
                  type="range"
                  min="0"
                  max="360"
                  v-model.number="figures1[selectedFigure1].style.deg"
                  @input="updateFigureStyle('transform', 'rotate(' + $event.target.value + 'deg)', 'figures1', 'selectedFigure1')"
              />
              {{ figures1[selectedFigure1].style.deg }}
            </label>

            <button class="close-modal-btn" @click="deselectFigure('selectedFigure1')">✖</button>
            <button @click="duplicateFigure('figures1', 'selectedFigure1')">{{ $t('duplicateFigure') }}</button>
            <button @click="deleteFigure('figures1', 'selectedFigure1')">{{ $t('deleteFigure') }}</button>
          </div>
        </div>

        <div class="position-relative">
          <div class="border-dashed">
            <div class="container-image" ref="figureContainer_block2">
              <img
                  v-for="(figure, index) in figures2"
                  :key="index"
                  :src="figure.src"
                  :alt="figure.alt"
                  class="figure draggable"
                  @click="selectFigure(index, 'figures2', 'selectedFigure2')"
                  :style="getFigureStyle(figure)"
              />
            </div>
          </div>
          <div class="settings-panel" v-if="selectedFigure2 !== null">
            <h3>{{ $t('figureSettings') }}</h3>
            <label>
              {{ $t('width') }}:
              <input
                  type="range"
                  min="10"
                  max="320"
                  v-model.number="figures2[selectedFigure2].style.widthNumeric"
                  @input="updateFigureStyle('width', $event.target.value + 'px', 'figures2', 'selectedFigure2')"
              />
              {{ figures2[selectedFigure2].style.width }}
            </label>

            <label>
              {{ $t('height') }}:
              <input
                  type="range"
                  min="10"
                  max="320"
                  v-model.number="figures2[selectedFigure2].style.heightNumeric"
                  @input="updateFigureStyle('height', $event.target.value + 'px', 'figures2', 'selectedFigure2')"
              />
              {{ figures2[selectedFigure2].style.height }}
            </label>

            <label>
              {{ $t('angle') }}:
              <input
                  type="range"
                  min="0"
                  max="360"
                  v-model.number="figures2[selectedFigure2].style.deg"
                  @input="updateFigureStyle('transform', 'rotate(' + $event.target.value + 'deg)', 'figures2', 'selectedFigure2')"
              />
              {{ figures2[selectedFigure2].style.deg }}
            </label>

            <button class="close-modal-btn" @click="deselectFigure('selectedFigure2')">✖</button>
            <button @click="duplicateFigure('figures2', 'selectedFigure2')">{{ $t('duplicateFigure') }}</button>
            <button @click="deleteFigure('figures2', 'selectedFigure2')">{{ $t('deleteFigure') }}</button>
          </div>
        </div>

        <div class="position-relative">
          <div class="border-dashed">
            <div class="container-image" ref="figureContainer_block3">
              <img
                  v-for="(figure, index) in figures3"
                  :key="index"
                  :src="figure.src"
                  :alt="figure.alt"
                  class="figure draggable"
                  @click="selectFigure(index, 'figures3', 'selectedFigure3')"
                  :style="getFigureStyle(figure)"
              />
            </div>
          </div>
          <div class="settings-panel" v-if="selectedFigure3 !== null">
            <h3>{{ $t('figureSettings') }}</h3>
            <label>
              {{ $t('width') }}:
              <input
                  type="range"
                  min="10"
                  max="320"
                  v-model.number="figures3[selectedFigure3].style.widthNumeric"
                  @input="updateFigureStyle('width', $event.target.value + 'px', 'figures3', 'selectedFigure3')"
              />
              {{ figures3[selectedFigure3].style.width }}
            </label>

            <label>
              {{ $t('height') }}:
              <input
                  type="range"
                  min="10"
                  max="320"
                  v-model.number="figures3[selectedFigure3].style.heightNumeric"
                  @input="updateFigureStyle('height', $event.target.value + 'px', 'figures3', 'selectedFigure3')"
              />
              {{ figures3[selectedFigure3].style.height }}
            </label>

            <label>
              {{ $t('angle') }}:
              <input
                  type="range"
                  min="0"
                  max="360"
                  v-model.number="figures3[selectedFigure3].style.deg"
                  @input="updateFigureStyle('transform', 'rotate(' + $event.target.value + 'deg)', 'figures3', 'selectedFigure3')"
              />
              {{ figures3[selectedFigure3].style.deg }}
            </label>

            <button class="close-modal-btn" @click="deselectFigure('selectedFigure3')">✖</button>
            <button @click="duplicateFigure('figures3', 'selectedFigure3')">{{ $t('duplicateFigure') }}</button>
            <button @click="deleteFigure('figures3', 'selectedFigure3')">{{ $t('deleteFigure') }}</button>
          </div>
        </div>

        <div class="position-relative">
          <div class="border-dashed">
            <div class="container-image" ref="figureContainer_block4">
              <img
                  v-for="(figure, index) in figures4"
                  :key="index"
                  :src="figure.src"
                  :alt="figure.alt"
                  class="figure draggable"
                  @click="selectFigure(index, 'figures4', 'selectedFigure4')"
                  :style="getFigureStyle(figure)"
              />
            </div>
          </div>
          <el-input placeholder="Подпиши фигуру" v-model="inputBlock4"></el-input>
          <div class="settings-panel" v-if="selectedFigure4 !== null">
            <h3>{{ $t('figureSettings') }}</h3>
            <label>
              {{ $t('width') }}:
              <input
                  type="range"
                  min="10"
                  max="320"
                  v-model.number="figures4[selectedFigure4].style.widthNumeric"
                  @input="updateFigureStyle('width', $event.target.value + 'px', 'figures4', 'selectedFigure4')"
              />
              {{ figures4[selectedFigure4].style.width }}
            </label>

            <label>
              {{ $t('height') }}:
              <input
                  type="range"
                  min="10"
                  max="320"
                  v-model.number="figures4[selectedFigure4].style.heightNumeric"
                  @input="updateFigureStyle('height', $event.target.value + 'px', 'figures4', 'selectedFigure4')"
              />
              {{ figures4[selectedFigure4].style.height }}
            </label>

            <label>
              {{ $t('angle') }}:
              <input
                  type="range"
                  min="0"
                  max="360"
                  v-model.number="figures4[selectedFigure4].style.deg"
                  @input="updateFigureStyle('transform', 'rotate(' + $event.target.value + 'deg)', 'figures4', 'selectedFigure4')"
              />
              {{ figures4[selectedFigure4].style.deg }}
            </label>

            <button class="close-modal-btn" @click="deselectFigure('selectedFigure4')">✖</button>
            <button @click="duplicateFigure('figures4', 'selectedFigure4')">{{ $t('duplicateFigure') }}</button>
            <button @click="deleteFigure('figures4', 'selectedFigure4')">{{ $t('deleteFigure') }}</button>
          </div>
        </div>

      </div>

<!--      <button @click="saveAndCompareImage">Сохранить</button>-->

      <div id="preview-container" style="margin-top: 20px;"></div>

      <!--      <button @click="sendCollaborationResults">Отправить</button>-->
      <el-button @click="openModal"
                 class="btn btn-danger">{{ $t('testing.finish-modal-button') }}
      </el-button>

    </div>
    <b-modal id="exit-modal" hide-footer hide-header>
      <div id="modal-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('exit-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <img class="modal-warning-img mb-3" src="/images/profile/modal-warning.svg">
          <h3 class="mb-0">{{modalTitle}}</h3>
          <p>{{ $t('testing.finish-modal-desc') }}</p>
          <div class="clearfix pt-3">
            <el-button @click="sendCreativitySubtest3Results(false)" class="btn btn-primary mx-2">
              {{ $t('testing.finish-modal-button') }}
            </el-button>
            <a @click="$bvModal.hide('exit-modal')"
               class="btn btn-outline-primary mx-2">{{ $t('testing.finish-modal-cancel') }}</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css';
import Loader from '../../components/Loader';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue';
import html2canvas from "html2canvas";
import resemble from "resemblejs";

Vue.use(VueViewer)
export default {
  name: "Index",
  data() {
    return {
      modalTitle: this.$t('testing.finish-modal-title'),
      timerInterval: null,
      countdown: 0,
      quiz: '',
      figures1: [
        {
          src: "/images/subtest2/kr.png",
          alt: "figure2",
          style: { top: "100px", left: "100px", transform: "rotate(0deg)", width: "80px", height: "80px", widthNumeric : 80, heightNumeric : 80, zIndex: 1, deg: 0 }
        },
        {
          src: "/images/subtest2/pkr.png",
          alt: "figure1",
          style: { top: "10px", left: "10px", transform: "rotate(0deg)", width: "80px", height: "30px", widthNumeric: 80, heightNumeric : 30, zIndex: 1, deg: 0  }
        },
        {
          src: "/images/subtest2/tr.png",
          alt: "figure3",
          style: { top: "200px", left: "200px", transform: "rotate(0deg)", width: "80px", height: "60px", widthNumeric : 80, heightNumeric : 60, zIndex: 1, deg: 0 }
        },
        {
          src: "/images/subtest2/kv.png",
          alt: "figure4",
          style: { top: "300px", left: "200px", transform: "rotate(0deg)", width: "80px", height: "80px", widthNumeric : 80, heightNumeric : 80, zIndex: 1, deg: 0 }
        }
      ],
      figures2: [
        {
          src: "/images/subtest2/kr.png",
          alt: "figure2",
          style: { top: "100px", left: "100px", transform: "rotate(0deg)", width: "80px", height: "80px", widthNumeric : 80, heightNumeric : 80, zIndex: 1, deg: 0 }
        },
        {
          src: "/images/subtest2/pkr.png",
          alt: "figure1",
          style: { top: "10px", left: "10px", transform: "rotate(0deg)", width: "80px", height: "30px", widthNumeric: 80, heightNumeric : 30, zIndex: 1, deg: 0  }
        },
        {
          src: "/images/subtest2/tr.png",
          alt: "figure3",
          style: { top: "200px", left: "200px", transform: "rotate(0deg)", width: "80px", height: "60px", widthNumeric : 80, heightNumeric : 60, zIndex: 1, deg: 0 }
        },
        {
          src: "/images/subtest2/kv.png",
          alt: "figure4",
          style: { top: "300px", left: "200px", transform: "rotate(0deg)", width: "80px", height: "80px", widthNumeric : 80, heightNumeric : 80, zIndex: 1, deg: 0 }
        }
      ],
      figures3: [
        {
          src: "/images/subtest2/kr.png",
          alt: "figure2",
          style: { top: "100px", left: "100px", transform: "rotate(0deg)", width: "80px", height: "80px", widthNumeric : 80, heightNumeric : 80, zIndex: 1, deg: 0 }
        },
        {
          src: "/images/subtest2/pkr.png",
          alt: "figure1",
          style: { top: "10px", left: "10px", transform: "rotate(0deg)", width: "80px", height: "30px", widthNumeric: 80, heightNumeric : 30, zIndex: 1, deg: 0  }
        },
        {
          src: "/images/subtest2/tr.png",
          alt: "figure3",
          style: { top: "200px", left: "200px", transform: "rotate(0deg)", width: "80px", height: "60px", widthNumeric : 80, heightNumeric : 60, zIndex: 1, deg: 0 }
        },
        {
          src: "/images/subtest2/kv.png",
          alt: "figure4",
          style: { top: "300px", left: "200px", transform: "rotate(0deg)", width: "80px", height: "80px", widthNumeric : 80, heightNumeric : 80, zIndex: 1, deg: 0 }
        }
      ],
      figures4: [
        {
          src: "/images/subtest2/kr.png",
          alt: "figure2",
          style: { top: "100px", left: "100px", transform: "rotate(0deg)", width: "80px", height: "80px", widthNumeric : 80, heightNumeric : 80, zIndex: 1, deg: 0 }
        },
        {
          src: "/images/subtest2/pkr.png",
          alt: "figure1",
          style: { top: "10px", left: "10px", transform: "rotate(0deg)", width: "80px", height: "30px", widthNumeric: 80, heightNumeric : 30, zIndex: 1, deg: 0  }
        },
        {
          src: "/images/subtest2/tr.png",
          alt: "figure",
          style: { top: "200px", left: "200px", transform: "rotate(0deg)", width: "80px", height: "60px", widthNumeric : 80, heightNumeric : 60, zIndex: 1, deg: 0 }
        },
        {
          src: "/images/subtest2/kv.png",
          alt: "figure4",
          style: { top: "300px", left: "200px", transform: "rotate(0deg)", width: "80px", height: "80px", widthNumeric : 80, heightNumeric : 80, zIndex: 1, deg: 0 }
        }
      ],
      currentFigure1: null,
      currentFigure2: null,
      currentFigure3: null,
      currentFigure4: null,
      offsetX: 0,
      offsetY: 0,
      selectedFigure1: null,
      selectedFigure2: null,
      selectedFigure3: null,
      selectedFigure4: null,
      zIndexCounter: 1,
      currentArray: '',
      sampleImages: {
        block1: ['/images/subtest2/examples/face/face1.svg', '/images/subtest2/examples/face/face2.svg', '/images/subtest2/examples/face/face3.svg', '/images/subtest2/examples/face/face4.svg', '/images/subtest2/examples/face/face5.svg',],
        block2: ['/images/subtest2/examples/home/dom1.svg', '/images/subtest2/examples/home/dom2.svg', '/images/subtest2/examples/home/dom3.svg', '/images/subtest2/examples/home/dom4.svg', '/images/subtest2/examples/home/dom5.svg',],
        block3: ['/images/subtest2/examples/clown/clown1.svg', '/images/subtest2/examples/clown/clown2.svg', '/images/subtest2/examples/clown/clown3.svg', '/images/subtest2/examples/clown/clown4.svg', '/images/subtest2/examples/clown/clown5.svg'],
        block4: [],
      },
      interpretationResult: {
        high: 'Высокий уровень креативности («признаки одаренности»). У тебя отличное воображение, ты способен думать быстро, мыслить творчески,   высказывать разнообразные идеи. Рекомендуем пройти 2 этап диагностики в оффлайн формате. Этого нужно записаться на сайте на индивидуальную консультацию к психологу.',
        middle: 'Средний уровень креативности. У тебя хорошее воображение, ты мыслишь достаточно быстро и творчески,  Рекомендуем пройти 2 этап диагностики в оффлайн формате. Этого нужно записаться на сайте на индивидуальную консультацию к психологу. ',
        low: 'Ниже среднего уровень креативности. У тебя хорошее воображение, но ты мог бы думать быстрее, оригинальнее и самостоятельно, придумывать собственные истории. Рекомендуется посещать психологические занятия для развития своего потенциала.',
      },
      blockImages: [],
      inputBlock4: '',
    };
  },
  methods: {
    validateAnswers() {
      return Boolean(this.inputBlock4)
    },
    duplicateFigure(nameArray, selectedFigure) {
      if (this[selectedFigure] !== null) {
        const figureToDuplicate = {
          ...this[nameArray][this[selectedFigure]],
          style: { ...this[nameArray][this[selectedFigure]].style,}
        };
        figureToDuplicate.style.top = parseInt(figureToDuplicate.style.top, 10) + 20 + 'px';
        figureToDuplicate.style.left = parseInt(figureToDuplicate.style.left, 10) + 20 + 'px';
        this[nameArray].push(figureToDuplicate);

        this.$nextTick(() => {
          document.querySelectorAll('.draggable').DraggableJS();
        });
      }
    },
    deleteFigure(nameArray, selectedFigure) {
      if (this[selectedFigure] !== null) {
        this[nameArray] = [...this[nameArray]].filter((item, index) => index != this[selectedFigure])
        this.deselectFigure(selectedFigure)
      }
    },
    updateFigureStyle(property, value, nameArray, selectedFigure) {
      this.$set(this[nameArray][this[selectedFigure]].style, property, value);
    },
    getFigureStyle(figure) {
      return {
        left: figure.style.left,
        top: figure.style.top,
        width: figure.style.width,
        height: figure.style.height,
        transform: figure.style.transform,
        'z-index' : figure.style.zIndex,
      };
    },
    selectFigure(index, nameArray, selectedFigure) {
      this.selectedFigure1 = selectedFigure === 'selectedFigure1' ? index : null;
      this.selectedFigure2 = selectedFigure === 'selectedFigure2' ? index : null;
      this.selectedFigure3 = selectedFigure === 'selectedFigure3' ? index : null;
      this.selectedFigure4 = selectedFigure === 'selectedFigure4' ? index : null;

      if (nameArray == "figures1" && index == 0) return
      if (nameArray == "figures3" && index == 0) return
      this.zIndexCounter += 1;
      this[nameArray][index].style.zIndex = this.zIndexCounter;
    },
    deselectFigure(selectedFigure) {
      this[selectedFigure] = null;
    },
    startDrag(event, index, nameArray) {
      const isTouchEvent = event.type.startsWith("touch");

      if (nameArray === 'figures1') this.currentFigure1 = index;
      if (nameArray === 'figures2') this.currentFigure2 = index;
      if (nameArray === 'figures3') this.currentFigure3 = index;
      if (nameArray === 'figures4') this.currentFigure4 = index;

      this.zIndexCounter += 1;
      this[nameArray][index].style.zIndex = this.zIndexCounter;
      this.currentArray = nameArray;

      const figure = event.target;
      const clientX = isTouchEvent ? event.touches[0].clientX : event.clientX;
      const clientY = isTouchEvent ? event.touches[0].clientY : event.clientY;

      this.offsetX = clientX - figure.offsetLeft;
      this.offsetY = clientY - figure.offsetTop;

      if (isTouchEvent) {
        document.addEventListener("touchmove", this.drag);
        document.addEventListener("touchend", this.stopDrag);
      } else {
        document.addEventListener("mousemove", this.drag);
        document.addEventListener("mouseup", this.stopDrag);
      }

      event.preventDefault();
    },
    drag(event) {
      let figure;
      if (this.currentArray === 'figures1') figure = this[this.currentArray][this.currentFigure1];
      if (this.currentArray === 'figures2') figure = this[this.currentArray][this.currentFigure2];
      if (this.currentArray === 'figures3') figure = this[this.currentArray][this.currentFigure3];
      if (this.currentArray === 'figures4') figure = this[this.currentArray][this.currentFigure4];

      const isTouchEvent = event.type.startsWith("touch");
      const clientX = isTouchEvent ? event.touches[0].clientX : event.clientX;
      const clientY = isTouchEvent ? event.touches[0].clientY : event.clientY;

      figure.style.left = `${Math.max(1, Math.min(clientX - this.offsetX, 310))}px`;
      figure.style.top = `${Math.max(1, Math.min(clientY - this.offsetY, 310))}px`;

      event.preventDefault();
    },
    stopDrag(event) {
      const isTouchEvent = event.type.startsWith("touch");

      if (isTouchEvent) {
        document.removeEventListener("touchmove", this.drag);
        document.removeEventListener("touchend", this.stopDrag);
      } else {
        document.removeEventListener("mousemove", this.drag);
        document.removeEventListener("mouseup", this.stopDrag);
      }

      this.currentFigure1 = null;
      this.currentFigure2 = null;
      this.currentFigure3 = null;
      this.currentFigure4 = null;
    },
    rotateFigure(event, index) {
      event.preventDefault();
      const figure = this.figures[index];

      if (!figure.style.transform) {
        figure.style.transform = 'rotate(0deg)';
      }

      const currentRotation = this.getRotation(figure.style.transform);
      const newRotation = currentRotation + (event.deltaY > 0 ? 15 : -15);
      figure.style.transform = `rotate(${newRotation}deg)`;
    },
    getRotation(transform) {
      const match = transform.match(/rotate\(([-\d.]+)deg\)/);
      return match ? parseFloat(match[1]) : 0;
    },
    async convertSvgToDataUrl(svgPath, width, height) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = svgPath;
        img.crossOrigin = "Anonymous";

        img.onload = async () => {
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/png"));
        };

        img.onerror = reject;
      });
    },
    async saveAndCompareImage() {
      const results = {};

      for (const blockId in this.sampleImages) {
        const container = this.$refs[`figureContainer_${blockId}`];
        const { width, height } = container.getBoundingClientRect();

        const originalBackgroundColor = container.style.backgroundColor;
        container.style.backgroundColor = "transparent";
        const canvas = await html2canvas(container, { backgroundColor: null });
        container.style.backgroundColor = originalBackgroundColor;
        const newImageURL = canvas.toDataURL("image/png");
        this.blockImages.push({blockId, newImageURL})

        if (blockId === "block4") {
          results[blockId] = { imageUrl: newImageURL };
          continue;
        }

        results[blockId] = [];

        for (let sampleIndex = 0; sampleIndex < this.sampleImages[blockId].length; sampleIndex++) {
          const svgURL = this.sampleImages[blockId][sampleIndex];
          const referenceImageURL = await this.convertSvgToDataUrl(svgURL, width, height);

          const comparisonResult = await new Promise((resolve) => {
            resemble(referenceImageURL)
                .compareTo(newImageURL)
                .ignoreColors()
                .onComplete((data) => {
                  resolve({
                    sampleIndex,
                    misMatchPercentage: data.misMatchPercentage,
                    isSameDimensions: data.isSameDimensions,
                  });
                });
          });

          results[blockId].push(comparisonResult);
        }
      }

      console.log("results:", results);
      console.log("blockImages", this.blockImages);
      return results;
    },
    interpretScore(score) {
      if (score >= 30) {
        return this.interpretationResult.high;
      } else if (score >= 15) {
        return this.interpretationResult.middle;
      } else {
        return this.interpretationResult.low;
      }
    },
    analyzeFigures(figures1, figures2, figures3, figures4) {
      const analyzeArray = (figuresArray) => {
        const numberFigures = figuresArray.length;
        const uniqueTypes = new Set(figuresArray.map(figure => figure.alt));
        const numberTypes = uniqueTypes.size;
        const score = numberFigures + numberTypes * 2;

        return { numberFigures, numberTypes, score };
      };

      const result1 = analyzeArray(figures1);
      const result2 = analyzeArray(figures2);
      const result3 = analyzeArray(figures3);
      const result4 = analyzeArray(figures4);

      const totalScore = result1.score + result2.score + result3.score + result4.score;

      return {
        figures1: result1,
        figures2: result2,
        figures3: result3,
        figures4: result4,
        score: totalScore
      };
    },
    filterBlocks(blocks) {
      const result = {};
      for (const block in blocks) {
        if (blocks.hasOwnProperty(block)) {
          let minItem = blocks[block].reduce((min, item) => parseFloat(item.misMatchPercentage) < parseFloat(min.misMatchPercentage) ? item : min, blocks[block][0]);
          minItem = {...minItem, level : this.interpretScore(minItem.misMatchPercentage)}
          result[block] = [minItem];
        }
      }
      return result;
    },
    openModal() {
      this.$bvModal.show('exit-modal');
    },
    async sendCreativitySubtest3Results(timer) {

      if (!timer && !this.validateAnswers()) {
        this.$bvModal.hide('exit-modal');

        return Vue.toastr({
          message: this.$t('error-title'),
          description: 'Добавьте описание к картинке',
          type: 'error'
        })
      }

      await this.saveAndCompareImage()

      let params = JSON.parse(localStorage.getItem('quiz-params-15-17'))
      const quizToken = localStorage.getItem('quiz-token')

      const creativitySubtest3Data = {
        score: this.analyzeFigures(this.figures1, this.figures2, this.figures3, this.figures4,).score,
        interpret: this.interpretScore(this.analyzeFigures(this.figures1, this.figures2, this.figures3, this.figures4,).score),
        userImage: this.blockImages,
        addInfo: {
          sampleImages: this.sampleImages,
          figures1: this.figures1,
          figures2: this.figures2,
          figures3: this.figures3,
          figures4: this.figures4,
          info: this.analyzeFigures(this.figures1, this.figures2, this.figures3, this.figures4,),
        }
      }

      params.answer_id = {...params.answer_id, creativitySubtest3Data}
      localStorage.setItem('quiz-params-15-17', JSON.stringify(params))

      const response = await this.$http.post(API_ROOT + '/api/user-answer/' + quizToken, params)

      if (response.body.status == 'success') {

        localStorage.setItem('current-test', 'hidden-form')

        Vue.toastr({
          message: this.$t('successful'),
          description: this.$t('saved-successfully'),
          type: 'success'
        })
      } else {
        Vue.toastr({
          message: this.$t('error-title'),
          description: this.$t('error-saving'),
          type: 'error'
        })
      }

      setTimeout(() => {
        this.$router.push({ path: '/hidden-form' })
      }, 1500)

    },
    startTimer(duration) {
      let timer = duration, minutes, seconds;
      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.countdown = minutes + ":" + seconds;

        let savedTimer = (parseInt(minutes) * 60) + parseInt(seconds);
        localStorage.setItem('timerCreativitySubtest3', savedTimer);

        if (--timer < 0) {
          this.sendCreativitySubtest3Results(true)
          clearInterval(this.timerInterval);
        }
      }, 1000);
    },
  },
  mounted() {
    if (localStorage.getItem('timerCreativitySubtest3')) {
      this.startTimer(localStorage.getItem('timerCreativitySubtest3'))
    } else {
      this.startTimer(300)
    }

    if (localStorage.getItem('current-test')) {
      this.$router.push({name: localStorage.getItem('current-test')});
    }

    const testLang = localStorage.getItem('test_lang');
    if (testLang) this.$i18n.locale = testLang;

    this.$nextTick(() => {
      document.querySelectorAll('.draggable').DraggableJS();
    });
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  }
}
</script>

<style>
.select-custom-collaboration {
  width: 240px !important;
}

.collaboration-desc {
  margin: 20px 0;
}

.collaboration-options {
  cursor: pointer;
  position: relative;
}

.el-select-group__title {
  position: relative;
}

.el-select-group__title::after {
  position: absolute;
  right: 10px;
  top: 2px;
  content: '▼';
}

.container-image {
  width: 400px;
  height: 400px;
  position: relative;
  overflow: hidden;
}

.figure {
  position: absolute;
  cursor: grab;
  user-select: none;
  transition: transform 0.1s ease;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
}

.border-dashed {
  border: 2px dashed #ccc;
}

@media screen and (max-width: 500px) {
  .container-image {
    width: 100%;
  }

  .image-container {
    flex-direction: column;
  }
}

</style>
